:root {
  --primary: #76FFD8;
  --fade: #E6F0F9;
  --body: 'Lato', sans-serif;
  --title: 'Montserrat', sans-serif;
  --white: white;
  --smoke: whitesmoke;
  --button: #FF4E00;
  --link: #0563c1;
  --background: #282c34;
}

html,
body {
  min-height: 100%;
  height: 100%;
  }

p {
  line-height: 1.2em;
}

.App {
  font-family: var(--body);
  background-color: var(--background);
  height: 100%;
  overflow: auto;
}

header {
  display: flex;
  align-items: center;
  justify-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 24px 40px 24px 40px;
  background-color:var(--background);
  box-shadow: 0px 4px 16px 0px rgba(80, 82, 85, 0.1);
}

header button {
  float: left;
  position: absolute;
  display: flex;
}

header button .material-symbols-outlined {
  font-size: 20px;
  padding-right: 8px;
  line-height: 14px;
}


header h1 {
  font-weight: 700;
  font-size: 40px;
  color: var(--primary);
  margin: auto;
  font-family: 'Montserrat', sans-serif;
}

.titlePrefix {
  color: #fff;
}

.menu button {
  background: none;
  border: none;
}

button {
  background-color: var(--button);
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  text-transform: uppercase;
  font-family: var(--title);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--smoke);
}

button:focus {
  outline: 2px solid var(--button);
  outline-offset: 2px ;
}

button:hover {
  opacity: 0.9;
}

.navMenu {
  background-color: #4c4f5f;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  transition: transform 0.2s ease-in;
  transform: translate(-100%, 0);
}

.navMenu.open {
  transform: translate(0);
}

.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}

.navPromptWrapper {
  border-bottom: 1px solid var(--background);
  padding: 10px;
  height: calc(100vh - 55px);
  overflow-y: auto;
}

.navPrompt {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}

.navPrompt a {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  text-decoration: none;
}

.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ececf1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.navPrompt span {
  opacity: 0;
}

#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
}

#botMessage li {
  padding-bottom: 0.75rem;
}

pre {
  font-family: var(--body);
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navCloseIcon {
  margin: 10px;
}

nav svg {
  float: left;
}

.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: #202022;
}

.sideMenuButton {
  border: 1px solid hsl(0deg 0% 100% / 20%);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}

.navPrompt:hover,
.sideMenuButton:hover {
  cursor: pointer;
}

.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}

.chatBox {
  background-color: #282c34;
  position: relative;
  color: black;
  font-size: 16px;
  padding-bottom: 96px;
}

.logo {
  width: 60vw;
  max-width: 516px;
  margin-top: 3vh;
  margin-bottom: 5vh;
}

#introsection::before,
#introsection::after {
  content: "";
  float: left;
  color: rgb(0, 134, 244);
}

#introsection {
  justify-content: center;
  text-align: center;
  padding-top: 60px;
}

#introsection h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 32px;
  color: var(--white);
}

#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}

#introsection ul {
  list-style-type: square;
}

#introsection pre {
  margin: 0;
  display: inline;
}

pre {
  white-space: pre-wrap;
  line-height: normal;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  margin: 24px auto;
  max-width: 800px;
  display: flex;
}

#chatPrompt {
  flex: 1;
  padding: 0px 24px;
  background-color: var(--fade);
  margin-left: 16px;
  border-radius: 4px;
  white-space: pre-wrap;
  word-break: break-word;
}

.userSVG {
  transform: scale(0.6);
}

#botMessage {
  flex: 1;
  padding: 0px 24px;
  background-color: var(--white);
  border-radius: 4px;
  margin-left: 16px;
}

.loading-spinner {
  flex: 1 ;
  padding: 16px 24px;
  background-color: var(--white);
  border-radius: 4px;
  margin-left: 16px;
  
}

#botMessage a {
  text-decoration: none;
  color: var(--link) !important;
  word-break: break-all;
}

#botMessage a:hover {
  text-decoration: underline;
  opacity: 0.9;
}

.botMessageWrapper {
  margin: 24px auto;
  max-width: 800px;
  display: flex;
}

.errorMessage {
  flex: 1;
  padding: 16px 24px;
  background-color: var(--white);
  margin-left: 16px;
  border-radius: 4px;
  color: crimson;
}

.messageLogo {
  margin-top: 4px;
}

#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

.inputPromptWrapper {
  background-color: #282c34;
  justify-content: center;
  padding: 12px 12px 12px 12px;
  display: flex;
  justify-items: center;
  width: 100%;
}

.inputPromptWrapper div {
  width: 800px;
  display: flex;
  margin-right: 29px;
}

.inputPromptTextArea {
  flex: 1;
  padding: 12px 16px;
  background-color: white;
  color: black;
  font-size: 16px;
  border: 2px solid white;
  overflow: hidden;
  box-shadow: 0px 8px 24px 0px rgba(161, 94, 17, 0.3);
  border-radius: 4px;
  margin: 0 0 0 12px;
  resize: none;
  height: 1.4rem;
  max-height: 5.5rem;
  overflow-y: auto;
}

.inputPromptTextArea:focus {
  outline: 2px solid var(--button);
}

form button {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 8px;
  box-shadow: 0px 8px 24px 0px rgba(5, 99, 193, 0.30);
}

@media (hover: hover) {
  button:hover {
    cursor: pointer;
    border-radius: 5%;
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  min-height: 100%;
  padding: clamp(15px, 5%, 50px);
  font-family: var(--body);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

#svgLogo {
  width: 100%;
  max-width: 516px;
  height: auto;
  padding-bottom: 60px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0 0 30px 0;
  font-weight: 700;
  color: #fff;
}

.loginContainer p {
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
  color: #fff;
}

.loginDescription {
  padding: 0 clamp(20px, 25%, 50px);
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin-top: 20px;
}

button.buttonImage {
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff;
  background: #76ffd8;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

button.buttonImage:hover {
  background-color: rgb(26, 127, 100);
}

button.buttonImage img {
  max-width: 100%;
  height: auto;
}

#expandedRefreshButton {
  visibility: visible;
  line-height: 14px;
}

#minimizedRefreshButton {
  visibility: hidden;
  border-radius: 100%;
  position: absolute;
  left: 25px;
  height: 60px;
  width: 60px;
  padding: 0;
  flex-wrap: wrap;
  align-content: center;
  .material-symbols-outlined {
    font-size: 30px;
    padding: 0;
    width: 100%;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: var(--background);
  padding-bottom: 25px;
}

.last-update {
  color: var(--white);
}

.projectSelector {
  position: absolute;
  right: 40px;
}

body.active-modal {
  overflow-y: hidden;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: rgba(49,49,49,0.8);
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: var(--background);
  color: var(--white);
  padding: 14px 28px;
  border-radius: 3px;
  border-color: var(--white);
  border-style: solid;
  border-width: 1px;
}

.form-control {
  max-width: 50vw;
  min-width: 50vw;
  max-height: 40vh;
  min-height: 40vh;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 28px;
  padding: 5px 7px;
}

.modal-footer{
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modalButton {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 8px;
  box-shadow: 0px 8px 24px 0px rgba(5, 99, 193, 0.30);
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (min-width: 768px) {
  .navMenu.open {
    display: none;
  }
}

@media screen and (max-width: 1250px) {
  .projectSelector {
    margin-top: 150px;
  }
  .sinfoniaHeader {
    padding: 24px 40px 124px 40px;
  }
}

@media screen and (max-width: 1024px) {
  nav {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .messageLogo {
    width: 32px;
    height: 32px;
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    align-items: center;
    padding: 40px;
    height: 60vh;
  }
  #introsection h1 {
    font-size: 32px;
    white-space: pre-wrap;
    text-align: center;
    font-weight: 700;
  }
  #introsection h2 {
    font-size: 14px;
  }
  .inputPromptWrapper {
    padding: 12px;
  }
  .logo {
    width: 70vw;
    max-width: 65vh;
    margin-top: 5vh;
    margin-bottom: 8vh;
  }
  #expandedRefreshButton {
    visibility: hidden;
  }
  #minimizedRefreshButton {
    visibility: visible;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    width: 75vw;
    max-width: 75vh;
    margin-top: 5vh;
    margin-bottom: 8vh;
  }
  #introsection {
    padding: 30px;
  }
  #introsection h1 {
    font-size: 20px;
    font-weight: 700;
  }
  .form-control {
    max-width: 60vw;
    min-width: 60vw;
  }
  header h1 {
    font-size: 35px;
    margin-left: 15%;
  }
  .modal-content {
    top: 60%;
    left: 50%;
  }
}

@media screen and (max-width: 450px) {
  header h1 {
    font-size: 20px;
    margin-left: 10%;
  }
  #minimizedRefreshButton {
    left: 15px;
    height: 30px;
    width: 30px;
    .material-symbols-outlined {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 400px) {
  .logo {
    width: 35vh;
    margin-top: 5vh;
    margin-bottom: 15vh;
  }
  #introsection {
    padding: 20px;
  }
  #introsection h1 {
    font-size: 20px;
    font-weight: 700;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  button.buttonImage {
    font-size: 22px;
  }
}

@media screen and (max-height: 768px) {
  .form-control {
    max-height: 25vh;
    min-height: 25vh;
  }
}
